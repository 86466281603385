import React, { useState, useEffect } from 'react';
import s from './Contact.module.scss';
import Button from '../../components/UIElements/Button/Button';
import BaseLayout from '../../layouts/BaseLayout/BaseLayout';

const Contact = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Email: '',
    Subject: '',
    Message: '',
    'Date & Time': '', // Adding 'Date & Time' field to formData
  });

  const [formErrors, setFormErrors] = useState({
    Name: '',
    Email: '',
    Subject: '',
    Message: '',
  });

  const [submitSuccess, setSubmitSuccess] = useState(false);

  useEffect(() => {
    let countdown = 3;

    const countdownInterval = setInterval(() => {
      if (submitSuccess && countdown > 0) {
        countdown -= 1;
      } else if (submitSuccess && countdown === 0) {
        clearInterval(countdownInterval);
        window.location.href = '/contact'; // Redirect URL
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [submitSuccess]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Capture the current date and time
        const currentDateAndTime = new Date().toLocaleString();

        // Update the form data, including the Date & Time field
        const updatedFormData = {
          ...formData,
          'Date & Time': currentDateAndTime,
        };

        const response = await fetch('https://api.sheetmonkey.io/form/mFHKy5zjPGJVDJDQSdWush', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(updatedFormData),
        });

        if (response.ok) {
          setSubmitSuccess(true);
          console.log('Form submitted:', updatedFormData);
        } else {
          console.error('Failed to submit form:', response.statusText);
        }
      } catch (error) {
        console.error('Error submitting form:', error.message);
      }
    }
  };

  const validateForm = () => {
    let valid = true;
    const newFormErrors = { ...formErrors };

    // Validate Name
    if (formData.Name.trim() === '') {
      newFormErrors.Name = 'Name is required';
      valid = false;
    }

    // Validate Email
    const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!EmailRegex.test(formData.Email)) {
      newFormErrors.Email = 'Invalid Email address';
      valid = false;
    }

    // Validate Subject
    if (formData.Subject.trim() === '') {
      newFormErrors.Subject = 'Subject is required';
      valid = false;
    }

    // Validate Message
    if (formData.Message.trim() === '') {
      newFormErrors.Message = 'Message is required';
      valid = false;
    }

    setFormErrors(newFormErrors);
    return valid;
  };

  return (
    <BaseLayout>
      <div className={s.content}>
        <div className={s.header}>
          <h1 className={s.title}>
            Contact <br /> Me
          </h1>
        </div>

        {submitSuccess ? (
          <div className={s.successMessage}>
            Form submitted successfully!
          </div>
        ) : (
          <form className={s.form} onSubmit={handleSubmit}>
            <div className={s.formGroup}>
              <label htmlFor="Name">Name</label>
              <input
                type="text"
                id="Name"
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                required
              />
              <span className={s.error}>{formErrors.Name}</span>
            </div>

            <div className={s.formGroup}>
              <label htmlFor="Email">Email</label>
              <input
                type="Email"
                id="Email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                required
              />
              <span className={s.error}>{formErrors.Email}</span>
            </div>

            <div className={s.formGroup}>
              <label htmlFor="Subject">Subject</label>
              <input
                type="text"
                id="Subject"
                name="Subject"
                value={formData.Subject}
                onChange={handleChange}
                required
              />
              <span className={s.error}>{formErrors.Subject}</span>
            </div>

            <div className={s.formGroup}>
              <label htmlFor="Message">Message</label>
              <textarea
                id="Message"
                name="Message"
                value={formData.Message}
                onChange={handleChange}
                required
              ></textarea>
              <span className={s.error}>{formErrors.Message}</span>
            </div>

            {/* Hidden input for capturing current date and time */}
            <input
              type="hidden"
              name="Date & Time"
              value={formData['Date & Time']}
            />

            <Button
              style={{ margin: 'auto', width: '15rem' }}
              className="primary"
              type="submit"
            >
              <span className={s.submitText}>Submit</span>
            </Button>
          </form>
        )}
      </div>
    </BaseLayout>
  );
};

export default Contact;
