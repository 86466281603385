import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBpBWwY9B23Ti-ZC05j7ErhAes9C7DxH6A",
  authDomain: "datav-81108.firebaseapp.com",
  projectId: "datav-81108",
  storageBucket: "datav-81108.appspot.com",
  messagingSenderId: "981097801858",
  appId: "1:981097801858:web:df2def6469e18530a72c9b",
  measurementId: "G-2V0SXW1P9Z"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
