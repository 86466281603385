import React, { useEffect } from 'react';
import axios from 'axios';
import { firestore } from './firebase'; 
import moment from 'moment-timezone';
import bowser from 'bowser';

const UserTracking = () => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const { ip } = response.data;

        const locationResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
        const { city, country_name } = locationResponse.data;

        const userAgent = navigator.userAgent;

        // Get current date and time in Indian Standard Time (IST)
        const ISTDateTime = moment.tz('Asia/Kolkata').format();
        const [date, time] = ISTDateTime.split('T'); // Split date and time

        // Determine device type based on platform (mobile, tablet, or PC)
        let deviceType;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          deviceType = 'Mobile';
        } else if (/iPad/i.test(navigator.userAgent)) {
          deviceType = 'Tablet';
        } else {
          deviceType = 'PC';
        }

        // Use bowser for accurate browser detection
        const browserInfo = bowser.parse(userAgent);
        const browserName = browserInfo.browser.name || 'Unknown Browser';

        // Check if IP address already exists in Firestore
        const existingIP = await firestore
          .collection('users')
          .where('ip', '==', ip)
          .get();

        if (existingIP.empty) {
          // Store new user data with initial session count
          await firestore.collection('users').add({
            ip,
            location: `${city}, ${country_name}`,
            userAgent,
            browserName,
            deviceType,
            date,
            sessions: [{
              startTime: time,
            }],
          });

          console.log('New user data stored in Firestore');
        } else {
          // Update existing entry with new session
          const userData = existingIP.docs[0].data();
          const sessions = userData.sessions || [];

          // Check if session for current time already exists
          const existingSessionIndex = sessions.findIndex(session => session.startTime === time);
          if (existingSessionIndex === -1) {
            // Add new session for the current time
            sessions.push({
              startTime: time,
            });

            // Update Firestore document with updated sessions array
            existingIP.docs[0].ref.update({
              sessions,
            });

            console.log('New session added for existing user');
          } else {
            console.log('Session for current time already exists');
          }
        }
      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchData();
  }, []);

  return <></>;
};

export default UserTracking;
