import s from './Logo.module.scss';
import { routes } from '../../routes/BaseRoutes';
import { Link } from 'react-router-dom';

const Logo = ({ className }) => {
  return (
    <div className={className}>
      <Link to={routes.HOME} aria-label="site logo">
        {/* Updated SVG for "VIKAS" */}
        <svg
          fill={s.logoPrimary}
          xmlns="http://www.w3.org/2000/svg"
          width="120"
          height="50"
          viewBox="0 0 120 50"
        >
          <text x="0" y="35" fontSize="30" fontWeight="bold" fontFamily="Arial">
            VIKAS
          </text>
        </svg>
        {/* End of Updated SVG for "VIKAS" */}
      </Link>
    </div>
  );
};

export default Logo;
